.rules{
    font-weight:200;
    font-size:18px;
}

.progress-bar{
    transition:1s linear;
    background-color: #7070ef;
}

.question-title{
    font-size:4em;
    color:#ffffff;
    font-style: italic;
}

.question-description{
    font-size:2.4em;
}

.question-sub-text{
    font-size:2.3em;
    font-weight:200;
    color: #cacaca;
}

.timer{
    font-size:22px;
    color:#cacaca;
    float:right;
}

.rounds{
    font-size:22px;
    color:#cacaca;
    float:left;
}

.question-container, .question-description, .rating-container{
    margin-top: 3rem;
}

.thumbs-up, .thumbs-down{
    opacity: 0.7;
    cursor: pointer;
    width: 50px;
    fill: white;
}

.thumbs-up:hover, .thumbs-down:hover{
    fill: #7070ef;
}

.thumbs-down{
    transform: rotate(180deg);
}

.thumbs-text {
    color: #505050;
}

@media (min-width: 768px) and (max-width: 991px) {

}

@media (min-width: 350px) and (max-width: 767px) {
    .question-container, .question-description, .rating-container{
        margin-top: 2rem;
    }

    .question-title{
        font-size:2.7em;
    }

    .question-description{
        font-size:1.9em;
    }

    .question-sub-text{
        font-size:1.8em;
    }

    .rounds, .timer {
        font-size: 18px;
    }
}


@media (max-width: 350px) {
    .question-container, .question-description, .rating-container{
        margin-top: 1rem;
    }

    .question-title{
        font-size:2.7em;
    }

    .question-description{
        font-size:1.6em;
    }

    .question-sub-text{
        font-size:1.5em;
    }

    .rounds, .timer {
        font-size: 16px;
    }
}