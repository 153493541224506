.btn-group-toggle>.btn{
    opacity:0.4;
    background-color:#555555;
}

.btn-group>.btn.active, .btn-secondary:not(:disabled):not(.disabled).active{
    background-color:#9161d0;
    opacity:1;
}

.game-link-container{
    background-color:#323232;
    border-radius:50px;
}

.game-link-input,.game-link-input:focus{
    height:60px;
    margin-left:36px;
    border-radius:50px;
    outline:none;
    border:none;
    background-color:transparent;
    color:gray;
}

.game-link-button{
    border-radius:50px;
    border: 1px solid white;
    background: linear-gradient(0deg,#ee0979,#ff6a00);
}
.game-link-button:active{
    border-radius:50px;
    border: 1px solid white;
    background: linear-gradient(180deg,#ee0979,#ff6a00);
}

.game-link-button-text{
    background-color: transparent;
    border: none;
    color: white;
}

.game-link-button-text-copied{
    background-color: transparent;
    border: none;
    color: #00ff66;
}