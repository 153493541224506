.btn{
    color:#ffffff;
    padding:16px 32px;
    font-weight:bold;
    border:0px;
    border-radius: 100px;
    text-transform: uppercase;
    font-family: Lato;
    letter-spacing: 1.6px;
}

.btn,.btn-primary{
    background-color:#ee0979;
}

.btn:hover, .btn:active,  .btn:focus {
    background-color:#bd0760;
    border-color: #bd0760;
    color: #ffffff;
    box-shadow: none;
}

.btn-group-toggle>.btn {
    background-color: #000000;
}

.btn-group>.btn.active, .btn-secondary:not(:disabled):not(.disabled).active {
    background-color: #BEB7A4;
}

.progress{
    background-color:#cacaca;
    margin-bottom: 1rem;
}


/* Mobile Screens */
@media (min-width: 350px) and (max-width: 767px){
    .btn {
        font-size: 14px;
        letter-spacing: 1.2px;
        padding:12px 24px;
    }

    .progress{
        margin-bottom: .5rem;
    }
}

/* Very Small Mobile Screens */
@media (max-width: 350px){
    .btn {
        font-size: 14px;
        letter-spacing: 1.2px;
        padding:8px 16px;
    }

    .progress{
        margin-bottom: .3rem;
    }
}