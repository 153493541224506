body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body{
  width:100%;
  height:100%;
  background-color:#212529;
  color:#dde2ef;
}

#content-holder {
  padding-top:2em;
  position: relative;
  z-index: 1;
}

#content-holder-parent, #root {
  height: 100%;
  position: relative;
  overflow: hidden;
}

#homepage-header {
  padding-top: 2em;
}

h1{
  font-size:6em;
}
h2{
  font-size:4em;
}
h3{
  font-size:4em;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Catamaran;
  font-weight: 800!important;
  color:#ffffff;
}

::selection {
  background: #ee0979;
}
::-moz-selection {
  background: #ee0979; 
}

.bg-circle-1 {
  height: 90rem;
  width: 90rem;
  bottom: -55rem;
  left: -55rem;
}

.bg-circle-2 {
  height: 50rem;
  width: 50rem;
  top: -25rem;
  right: -25rem;
}

.bg-circle-3 {
  height: 20rem;
  width: 20rem;
  bottom: -10rem;
  right: 5%;
}

.bg-circle-4 {
  height: 30rem;
  width: 30rem;
  top: -5rem;
  right: 35%;
}

.bg-circle {
  z-index: 0;
  position: absolute;
  border-radius: 100%;
  background: linear-gradient(0deg,#ee0979 0,#ff6a00 100%);
}

/* Mobile Screens */
@media (max-width: 767px) {
  .bg-circle-1 {
    height: 20rem;
    width: 20rem;
    bottom: -10rem;
    left: -10rem;
  }
  
  .bg-circle-2 {
    height: 15rem;
    width: 15rem;
    top: -8rem;
    right: -8rem;
  }
  
  .bg-circle-3 {
    display: none;
  }
  
  .bg-circle-4 {
    height: 25rem;
    width: 25rem;
    top: -5rem;
    right: 40%;
  }

  #homepage-header {
    padding-top: 20%;
  }
  
  h1{
    font-size:5em;
  }
  h2{
    font-size:3em;
  }
  h3{
    font-size:2em;
  }
}

/* Tablets */
@media (min-width: 768px) and (max-width: 991px) {
  .bg-circle-1 {
    height: 30rem;
    width: 30rem;
    bottom: -15rem;
    left: -15rem;
  }
  
  .bg-circle-2 {
    height: 15rem;
    width: 15rem;
    top: -8rem;
    right: -8rem;
  }
  
  .bg-circle-3 {
    display: none;
  }
  
  .bg-circle-4 {
    height: 30rem;
    width: 30rem;
    top: -5rem;
    right: 35%;
  }
}