.facebook {
    width: 2.7em;
    height: 2.7em;
    stroke: white;
    stroke-width: 1;
    padding: 20px;
    font-size: 30px;
    text-align: center;
    text-decoration: none;
    border-radius: 100%;
    background: #3B5998;
}

.facebook:hover {
    background: #32508F;
    color: white;
    text-decoration: none;
}